import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { getUrlParam } from '../../helpers/others'
import { useCookies } from 'react-cookie'
import { request } from '../../helpers/request'
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded'
import CancelIcon from '@mui/icons-material/Cancel'
import { primaryPrimary } from '../../style'

export const FormToLogin = ({
  setLocalSecretKey,
}: {
  setLocalSecretKey: (value: string) => void
}) => {
  const [tempLocalSecretKey, setTempLocalSecretKey] = useState('')
  return (
    <div style={{ backgroundColor: primaryPrimary, height: '100vh', display: 'flex' }}>
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
          <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h6" gutterBottom>
              Para validar cupones, ingrese la clave secreta del establecimiento
            </Typography>
            <TextField
              fullWidth
              label="Clave secreta"
              value={tempLocalSecretKey}
              onChange={(e) => setTempLocalSecretKey(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={() => setLocalSecretKey(tempLocalSecretKey.toLowerCase())}
              fullWidth
            >
              Enviar
            </Button>
          </Box>
        </Paper>
      </Container>
    </div>
  )
}

export const ValidateCuponScreen = () => {
  const [cookies, setCookie] = useCookies<any>(['savemoney'])
  const [localSecretKey, setLocalSecretKey] = useState(cookies.localSecretKey?.toLowerCase())
  const [isValid, setIsValid] = useState<boolean | null>(null)
  const [message, setMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const randomDeviceIdString =
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  const deviceId = cookies.deviceId || randomDeviceIdString

  const cuponId = getUrlParam('cuponId')

  useEffect(() => {
    setIsLoading(true)
    if (localSecretKey && cuponId) {
      request('validate_cupon', {
        method: 'POST',
        body: JSON.stringify({
          cuponId,
          localSecretKey,
          deviceId,
        }),
      }).then((response) => {
        setIsValid(response?.data?.valid || false)
        setMessage(response?.message || null)
        setIsLoading(false)
      })
    }
  }, [localSecretKey, cuponId])

  useEffect(() => {
    setCookie('localSecretKey', localSecretKey)
    setCookie('deviceId', deviceId)
  }, [localSecretKey, deviceId])

  if (!cuponId) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h6" sx={{ mt: 4 }}>
          No se encontró el ID del cupón
        </Typography>
      </Container>
    )
  }

  if (!localSecretKey) {
    return <FormToLogin setLocalSecretKey={setLocalSecretKey} />
  }

  return (
    <div style={{ backgroundColor: primaryPrimary, height: '100vh', display: 'flex' }}>
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
          {isLoading ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <CircularProgress size={60} />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mb: 4,
                }}
              >
                {isValid ? (
                  <>
                    <CheckCircleRounded
                      sx={{
                        fontSize: 120,
                        color: 'success.main',
                        mb: 2,
                      }}
                    />
                    <Typography variant="h4" color="success.main" fontWeight="500">
                      Cupón válido
                    </Typography>
                  </>
                ) : (
                  <>
                    <CancelIcon
                      sx={{
                        fontSize: 120,
                        color: 'error.main',
                        mb: 2,
                      }}
                    />
                    <Typography variant="h4" color="error.main" fontWeight="500">
                      Cupón inválido
                    </Typography>
                    <Typography variant="body1" color="error.main" fontWeight="500">
                      {message}
                    </Typography>
                  </>
                )}
              </Box>

              <Box
                sx={{
                  mt: 'auto',
                  pt: 3,
                  borderTop: 1,
                  borderColor: 'divider',
                  opacity: 0.7,
                }}
              >
                <Typography variant="caption" display="block">
                  ID del cupón: {cuponId}
                </Typography>
                <Typography variant="caption" display="block">
                  Clave secreta: {localSecretKey}
                </Typography>
                <Typography variant="caption" display="block" sx={{ mb: 1 }}>
                  ID del dispositivo: {deviceId}
                </Typography>
                <Button
                  variant="text"
                  color="inherit"
                  size="small"
                  onClick={() => setLocalSecretKey('')}
                  sx={{ opacity: 0.7 }}
                >
                  Cerrar sesión
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </Container>
    </div>
  )
}
