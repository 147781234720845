import React, { forwardRef, useContext, useEffect, useState } from 'react'
import { Discount } from '../../../types/discount'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { SMP } from '../../../SMComponents/SMText'
import { neutralWhite, primaryPrimary } from '../../../style'
import { logSMEvent } from '../../../events/logEvent'
import { MAINTAGV3Enum } from '../../../types/mainTagV3'
import { AppContext, UserAuthType } from '../../../contexts/AppContext'
import { DiscountContext } from '../../../contexts/DiscountContext'
import {
  OPEN_CUPON_FIELDS_REQUIRED,
  isAuthAllowedToOpenCupon,
} from '../../../consts/loginRestrictions'
import { CTAButtonSource } from './CTAButton'
import { Timestamp } from 'firebase/firestore'

interface CuponCTAButtonProps {
  discount: Discount
  source: CTAButtonSource
  category?: MAINTAGV3Enum | 'SaveMoney'
}

export const doesAuthHaveThisCuponActive = (cuponId: string, auth: UserAuthType | null | false) => {
  if (!auth) return false
  return (
    auth?.activeGeneratedCupons?.some((cupon) => {
      if (cupon.discountId === cuponId) {
        if (cupon.expiresAt) {
          return cupon.expiresAt > Timestamp.now()
        }
        return true
      }
      return false
    }) || false
  )
}

export const CuponCTAButton = forwardRef<HTMLButtonElement, CuponCTAButtonProps>(
  ({ discount, source, category }, ref) => {
    const { auth } = useContext(AppContext)
    const { handleOpenLoginDrawer, openCuponModal } = useContext(DiscountContext)
    const [isCuponActivatedByUser, setIsCuponActivatedByUser] = useState(false)

    useEffect(() => {
      if (auth) {
        setIsCuponActivatedByUser(doesAuthHaveThisCuponActive(discount.id, auth))
      }
    }, [auth])

    const onClickCupon = () => {
      const handleAction = async (userAuth: UserAuthType) => {
        logSMEvent('CUPON_MODAL_OPENED', {
          id: discount.id,
          isCuponActivatedByUser,
        })
        openCuponModal({ userAuth, discount })
      }

      if (!auth || !isAuthAllowedToOpenCupon(auth)) {
        handleOpenLoginDrawer({
          source: 'cupon_button', // antes era 'like_button'
          title: 'Completa tu perfil para generar un cupón',
          subtitle: 'Una vez generado el cupón, podrás encontrarlo en tus descuentos guardados.',
          fields: OPEN_CUPON_FIELDS_REQUIRED,
          buttonLabel: 'Generar cupón',
          onSubmit: handleAction,
        })
      } else {
        handleAction(auth)
      }
    }

    return (
      <SMPrimaryButton
        ref={ref}
        sx={{
          minWidth: 'fit-content', // to override the default minWidth
          minHeight: 0, // to override the default minHeight
          maxWidth: 240,
          maxHeight: 30,
          padding: 10,
          textAlign: 'start',
          color: neutralWhite,
          backgroundColor: primaryPrimary,
          gap: 6,
          whiteSpace: 'nowrap', // Prevent text wrapping
        }}
        onClick={(e: any) => {
          e.stopPropagation()
          const logEventName = isCuponActivatedByUser
            ? 'CLICKED_VER_CUPON'
            : 'CLICKED_GENERAR_CUPON'
          logSMEvent(`${logEventName}`, {
            discountId: discount.id,
            source,
            discountTitle: discount.titulo,
            discountClub: discount.club,
            isCuponActivatedByUser,
            category,
            isAd: discount.club === 'SaveMoney',
            userId: auth ? auth.id : null,
            ctaText: discount.CTA?.text,
            ctaUrl: discount.CTA?.url,
            authPhoneNumber: auth ? auth.phoneNumber : null,
          })
          onClickCupon()
        }}
      >
        <SMP white>{isCuponActivatedByUser ? 'Ver tu cupón' : 'Generar cupón'}</SMP>
      </SMPrimaryButton>
    )
  }
)

CuponCTAButton.displayName = 'CuponCTAButton'
