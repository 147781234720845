import React, { useContext, useEffect, useState } from 'react'

import styles from './discountCard.module.css'
import { showTwoDecimals } from '../../../helpers/location'
import { normalizeDaysToText, transformDaysText } from '../../../helpers/others'
import { neutral50White, primary40White, primaryPrimary } from '../../../style'
import { AppContext } from '../../../contexts/AppContext'
import { logSMEvent } from '../../../events/logEvent'
import { Discount } from '../../../types/discount'
import { SMHorizontalDiv, SMVerticalDiv } from '../../../SMComponents/SMView'
import { DiscountContext } from '../../../contexts/DiscountContext'
import { SMH3, SMP } from '../../../SMComponents/SMText'
import { StarOptions } from '../../admin/components/StarOptions'
import { IS_PROD_ENV } from '../../../consts/env'
import { MAINTAGV3Enum } from '../../../types/mainTagV3'
import { ClubEnums } from '../../../types/clubs'
import useObserver from '../../../hooks/useObserver'
import { DiscountActionFooter } from './DiscountActionFooter'

const { discountCard, discountHeaderFrame, backgroundImage, gMapPhotoInSlider } = styles

const DiscountCard = function ({
  discount,
  category,
  isStar,
}: {
  discount: Discount
  category: MAINTAGV3Enum
  isStar: boolean
}) {
  // escribe discount.XClosestLocation y discount.XGoogleMapsLink

  const { clubs } = useContext(AppContext)
  const { handleDiscountClick } = useContext(DiscountContext)
  const [wasObserved, setWasObserved] = useState(false) // is or was observed
  const handleObservation = () => {
    setWasObserved(true)
  }

  useEffect(() => {
    if (wasObserved) {
      const discountSummaryToLog = {
        id: discount.id,
        title: discount.titulo,
        category,
        club: discount.club,
      }
      logSMEvent('DISCOUNT_IMPRESSION', { ...discountSummaryToLog })
    }
  }, [wasObserved])

  const componentRef = useObserver(handleObservation)

  const showDistance =
    discount.XClosestLocation && (discount.XClosestLocation.distance < 10 || !IS_PROD_ENV)

  const onDiscountCardClicked = () => {
    logSMEvent('DISCOUNT_CARD_CLICKED', {
      id: discount.id,
      url: discount.url,
      mainTagV3: discount.mainTagV3,
      club: discount.club,
      discountClub: discount.club, // esto es para el reporte V2, para que la key sea la misma que el CTA_BUTTON_CLICKED
      discountTitle: discount.titulo,
    })
    handleDiscountClick(discount)
  }

  const discountShadow =
    discount.club === 'SaveMoney'
      ? `0px 1px 10px 6px  ${primary40White}`
      : '0px 10px 30px 10px  rgba(0,0,0,0.2)'

  return (
    <div
      ref={componentRef}
      className={discountCard}
      key={discount.id}
      onClick={onDiscountCardClicked}
      style={{
        cursor: 'pointer',
        boxShadow: discountShadow,
      }}
    >
      <div className={discountHeaderFrame}>
        <img // background para que se vea lindo atras de la imagen
          className={backgroundImage}
          src={discount.bgimage}
          alt="."
          referrerPolicy="no-referrer"
        />
        {discount.logo && discount.logo !== discount.bgimage && (
          <img
            alt="."
            src={discount.logo}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
        <img
          style={{
            marginRight: discount?.gMapsResults ? '2px' : '0px',
            width: '100%',
            objectFit:
              discount.club == ClubEnums.CLARO || discount.club == ClubEnums.LIDERBCI
                ? 'contain'
                : 'cover',
          }}
          src={discount.bgimage}
          alt="."
          referrerPolicy="no-referrer"
        />
        {discount?.gMapsResults
          ?.map((r) => r.photosUrls)
          ?.flat()
          ?.slice(0, 10)
          ?.map((url, index) => {
            return <img key={index} src={url} alt="." className={gMapPhotoInSlider} />
          })}
      </div>
      <SMVerticalDiv sx={{ margin: '10px', gap: 1 }}>
        <SMHorizontalDiv>
          {discount.club && clubs && discount.club !== 'SaveMoney' ? (
            <img
              src={clubs[discount.club]?.img}
              style={{ maxWidth: 30, maxHeight: 20, objectFit: 'contain' }}
              alt={discount.club}
            />
          ) : null}
          <SMH3
            style={{
              color: primaryPrimary,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {discount.titulo}
          </SMH3>
        </SMHorizontalDiv>

        <SMHorizontalDiv>
          {showDistance && discount.XClosestLocation?.distance !== undefined && (
            <>
              <SMP noWrap>a {showTwoDecimals(discount.XClosestLocation.distance)} km</SMP>
              <div
                style={{
                  minWidth: '6px',
                  minHeight: '6px',
                  backgroundColor: neutral50White,
                  borderRadius: '50%',
                }}
              />
            </>
          )}
          {discount.club === 'SaveMoney' && discount.cupon ? (
            <>
              <SMP noWrap>Cupón SaveMoney</SMP>
              <div
                style={{
                  minWidth: '6px',
                  minHeight: '6px',
                  backgroundColor: neutral50White,
                  borderRadius: '50%',
                }}
              />
            </>
          ) : discount.club === 'SaveMoney' ? (
            <>
              <SMP>Publicidiad</SMP>
              <div
                style={{
                  minWidth: '6px',
                  minHeight: '6px',
                  backgroundColor: neutral50White,
                  borderRadius: '50%',
                }}
              />
            </>
          ) : null}
          <SMP noWrap>{transformDaysText(normalizeDaysToText(discount.diasNormalizados))}</SMP>
          <div
            style={{
              minWidth: '6px',
              minHeight: '6px',
              backgroundColor: neutral50White,
              borderRadius: '50%',
            }}
          />
          <SMP
            noWrap
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {discount.local}
          </SMP>
        </SMHorizontalDiv>
        <DiscountActionFooter discount={discount} category={category} />
        {isStar && (
          <>
            <StarOptions discount={discount} />
            <SMP small sx={{ textAlign: '' }}>
              AI actualización:{' '}
              {new Intl.DateTimeFormat('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              }).format(new Date((discount?.aiQueryOutputTime?._seconds | 0) * 1000))}
            </SMP>
            <SMP small sx={{ textAlign: '' }}>
              GMaps actualización:{' '}
              {new Intl.DateTimeFormat('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              }).format(new Date((discount?.gMapsOutputTime?._seconds | 0) * 1000))}
            </SMP>
          </>
        )}
      </SMVerticalDiv>
    </div>
  )
}

export default DiscountCard
