import React, { useContext, useEffect, useState } from 'react'

import { request } from '../../../helpers/request'
import { Discount } from '../../../types/discount'
import { SMIconButton, SMPrimaryButton } from '../../../SMComponents/SMButton'
import { SMHorizontalDiv, SMVerticalDiv } from '../../../SMComponents/SMView'
import { SMTextField } from '../../../SMComponents/SMInput'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import { AppContext } from '../../../contexts/AppContext'
import { Checkbox } from '@mui/material'
import { DiscountContext } from '../../../contexts/DiscountContext'

export const StarOptions = ({ discount }: { discount: Discount }) => {
  const { handleOpenSnackBar } = useContext(AppContext)
  const { restaurantList } = useContext(DiscountContext)

  const [isLoadingAiFields, setIsLoadingAiFields] = useState(false)
  const [isLoadingGMapsFields, setIsLoadingGMapsFields] = useState(false)
  const [isLoadingHide, setIsLoadingHide] = useState(false)
  const [isEditingLocal, setIsEditingLocal] = useState(false)
  const [newLocal, setNewLocal] = useState(discount.local)
  const [isEditingDirecciones, setIsEditingDirecciones] = useState(false)
  const [newDirecciones, setNewDirecciones] = useState(discount.direcciones)
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const [newTitle, setNewTitle] = useState(discount.titulo)
  const [isNotCoverManager, setIsNotCoverManager] = useState(discount.isCoverManager === false)

  const [isEditingCTA, setIsEditingCTA] = useState(false)
  const [newCTA, setNewCTA] = useState(
    discount.CTA || {
      url: '',
      text: 'Reservar mesa',
    }
  )

  const restaurant = restaurantList.find((r) => r.slug === discount.local)
  useEffect(() => {
    if (restaurant) {
      if (restaurant.reservationSystem === 'COVER_MANAGER') {
        setNewCTA({
          url: restaurant.reservationUrl,
          text: 'Reservar mesa',
        })
      } else {
        setIsNotCoverManager(true)
      }
    }
  }, [restaurant])

  const handleUpdateDiscount = async (newFields: any) => {
    const response = await request('update_discount', {
      method: 'POST',
      body: JSON.stringify({
        discountId: discount.id,
        newFields,
      }),
    })
    console.log(response?.data || 'no data')
    handleOpenSnackBar({
      message: response?.message || 'Error',
    })
  }

  return (
    <div>
      <SMHorizontalDiv>
        {isEditingCTA ? (
          <SMVerticalDiv>
            <SMTextField
              value={newCTA.text || ''}
              placeholder="CTA Text"
              sx={{ backgroundColor: 'white', width: 300 }}
              onChange={(e: any) => setNewCTA({ ...newCTA, text: e.target.value })}
              onClick={(e: any) => e.stopPropagation()}
            />
            <SMTextField
              value={newCTA.url || ''}
              placeholder="CTA URL (Cover Manager)"
              sx={{ backgroundColor: 'white', width: 300 }}
              onChange={(e: any) => setNewCTA({ ...newCTA, url: e.target.value })}
              onClick={(e: any) => e.stopPropagation()}
            />
          </SMVerticalDiv>
        ) : (
          <SMVerticalDiv fullWidth flex1>
            <p>CTA Text: {newCTA.text}</p>
            <p>CTA URL CoverManager: {newCTA.url}</p>
          </SMVerticalDiv>
        )}
        <SMIconButton
          onClick={() => {
            const wantsToSaveChanges = isEditingCTA
            setIsEditingCTA(!isEditingCTA)
            if (wantsToSaveChanges) {
              handleUpdateDiscount({
                CTA: newCTA,
                isCoverManager: true,
              })
            }
          }}
        >
          {isEditingCTA ? <SaveRoundedIcon /> : '✎'}
        </SMIconButton>
      </SMHorizontalDiv>

      <SMHorizontalDiv>
        <p>IsNotCoverManager </p>
        <Checkbox
          checked={isNotCoverManager === true}
          onChange={() => setIsNotCoverManager(!isNotCoverManager)}
          onClick={(e: any) => e.stopPropagation()}
          name="isNotCoverManager"
          color="primary"
        />
        {isNotCoverManager === true ? (
          <SMIconButton
            onClick={(e: any) => {
              handleUpdateDiscount({ isCoverManager: false })
              e.stopPropagation()
            }}
          >
            <SaveRoundedIcon />
          </SMIconButton>
        ) : null}
      </SMHorizontalDiv>
      <SMPrimaryButton
        sx={{ backgroundColor: 'green' }}
        onClick={(e: any) => {
          e.preventDefault()
          e.stopPropagation()
          window.open(
            'https://www.google.com/search?q=' + 'reserva+restaurante+' + discount?.local,
            '_blank'
          )
        }}
      >
        Goog
      </SMPrimaryButton>

      <SMHorizontalDiv>
        {isEditingTitle ? (
          <SMTextField
            value={newTitle || ''}
            placeholder="Title"
            sx={{ backgroundColor: 'white', width: 300 }}
            onChange={(e: any) => setNewTitle(e.target.value)}
            onClick={(e: any) => e.stopPropagation()}
          />
        ) : (
          <p>Título: {newTitle}</p>
        )}
        <SMIconButton
          onClick={() => {
            const wantsToSaveChanges = isEditingTitle
            setIsEditingTitle(!isEditingTitle)
            if (wantsToSaveChanges) {
              handleUpdateDiscount({ titulo: newTitle })
            }
          }}
        >
          {isEditingTitle ? <SaveRoundedIcon /> : '✎'}
        </SMIconButton>
      </SMHorizontalDiv>
      <SMHorizontalDiv>
        {isEditingLocal ? (
          <SMTextField
            value={newLocal || ''}
            placeholder="Local"
            sx={{ backgroundColor: 'white', width: 300 }}
            onChange={(e: any) => setNewLocal(e.target.value)}
            onClick={(e: any) => e.stopPropagation()}
          />
        ) : (
          <p>Local: {newLocal}</p>
        )}
        <SMIconButton
          onClick={() => {
            const wantsToSaveChanges = isEditingLocal
            setIsEditingLocal(!isEditingLocal)
            if (wantsToSaveChanges) {
              handleUpdateDiscount({ local: newLocal })
            }
          }}
        >
          {isEditingLocal ? <SaveRoundedIcon /> : '✎'}
        </SMIconButton>
      </SMHorizontalDiv>
      <SMHorizontalDiv>
        {isEditingDirecciones ? (
          <SMTextField
            value={newDirecciones || ''}
            placeholder="Direccion"
            sx={{ backgroundColor: 'white', width: 300 }}
            onChange={(e: any) => setNewDirecciones(e.target.value)}
            onClick={(e: any) => e.stopPropagation()}
          />
        ) : (
          <p>Direcciones: {newDirecciones}</p>
        )}
        <SMIconButton
          onClick={() => {
            const wantsToSaveChanges = isEditingDirecciones
            setIsEditingDirecciones(!isEditingDirecciones)
            if (wantsToSaveChanges) {
              handleUpdateDiscount({ direcciones: newDirecciones })
            }
          }}
        >
          {isEditingDirecciones ? <SaveRoundedIcon /> : '✎'}
        </SMIconButton>
      </SMHorizontalDiv>
      <p>GMapsQueries: {discount.gMapsQueries?.join(' ; ')}</p>
      <SMHorizontalDiv>
        <p>MainTagV3: {discount.mainTagV3?.join(', ')}</p>
      </SMHorizontalDiv>

      <p>Regiones: {discount.regiones?.length == 16 ? 'TODAS' : discount.regiones?.join('. ')}</p>
      <div>
        <SMPrimaryButton
          variant="contained"
          loading={isLoadingAiFields}
          color="secondary"
          sx={{ margin: '8px 0px' }}
          onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
            setIsLoadingAiFields(true)
            request(`re_do_discount_ai_fields/${discount.id}`).then((response) => {
              console.log(response?.data || 'no data')
              setIsLoadingAiFields(false)
            })
          }}
        >
          ↺ AI
        </SMPrimaryButton>
        <SMPrimaryButton
          variant="contained"
          loading={isLoadingGMapsFields}
          color="primary"
          sx={{ margin: '2px 2px' }}
          onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
            setIsLoadingGMapsFields(true)
            request(`update_one_discount_gmaps/${discount.id}`).then((response) => {
              console.log(response?.data || 'no data')
              setIsLoadingGMapsFields(false)
            })
          }}
        >
          ↺ GM
        </SMPrimaryButton>
        <SMPrimaryButton
          sx={{ backgroundColor: 'green' }}
          href={
            'https://console.firebase.google.com/u/0/project/star-15bb5/firestore/databases/-default-/data/~2Fdiscounts~2F' +
            discount?.id
          }
        >
          BD
        </SMPrimaryButton>
        <SMPrimaryButton
          variant="contained"
          loading={isLoadingHide}
          sx={{ margin: '2px 2px', backgroundColor: 'black' }}
          onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
            setIsLoadingHide(true)
            request(`hide/${discount.id}`).then((response) => {
              console.log(response?.data || 'no data')
              setIsLoadingHide(false)
            })
          }}
        >
          d=f
        </SMPrimaryButton>
      </div>
    </div>
  )
}
