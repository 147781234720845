export enum ClubEnums {
  BANCODECHILE = 'Banco de Chile',
  BICE = 'BICE',
  SANTANDER = 'Santander',
  SECURITY = 'Banco Security',
  CMR = 'CMR',
  SCOTIABANK = 'Scotiabank',
  RIPLEY = 'Banco Ripley',
  MOVISTAR = 'Movistar',
  VLASCONDES = 'Vecino Las Condes',
  VITACURA = 'Mi Vitacura',
  REINA = 'La Reina',
  MERCURIO = 'Club Lectores El Mercurio',
  TERCERA = 'La Tercera',
  RAPPI = 'RappiCard',
  TENPO = 'Tenpo',
  SBPAY = 'Sbpay',
  CHEK = 'Chek',
  ENTEL = 'Entel',
  BESTADO = 'Banco Estado',
  FULLCOPEC = 'FullCopec',
  CLARO = 'Claro',
  LIDERBCI = 'Lider Bci',
  CAJALOSANDES = 'Caja Los Andes',
  MERCADOPAGO = 'Mercado Pago',

  SAVEMONEY = 'SaveMoney',
}

export type SubTarjetasType = {
  id: string // mismo que en el backend (openai enum)
  label: string
  img?: string
  width?: string
  display?: boolean
}

export type ClubsTypeDB = {
  id?: ClubEnums
  display?: boolean
  category?: string
  img?: string
  label?: string
  order?: number
  width?: number
  subTarjetas?: SubTarjetasType[]
}
