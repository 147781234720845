import React, { useState, useEffect, useContext } from 'react'

import '../../../App.css'
import { DiscountViewsRouter } from './DiscountViewsRouter'
import { LoginDrawer } from '../components/LoginDrawer'
import {
  DiscountContext,
  DiscountContextType,
  OpenLoginDrawerProps,
  OpenReportErrorProps,
  defaultAdDrawerProps,
  defaultLoginDrawerProps,
  defaultReportErrorDrawerProps,
  OpenAdDrawerProps,
  defaultCuponModalProps,
  OpenCuponModalProps,
} from '../../../contexts/DiscountContext'
import { ReportErrorDrawer } from '../components/ReportErrorDrawer'
import { getUrlParam, setUrlParam } from '../../../helpers/others'
import { Discount } from '../../../types/discount'
import { DiscountDrawer } from '../components/DiscountDrawer'
import { DiscountsDisplayEnum, DiscountsViewsEnum } from '../../../enums/DiscountsViewsEnum'
import { MenuDrawer } from '../components/MenuDrawer'
import { AppContext } from '../../../contexts/AppContext'
import { AdDrawer } from '../components/AdDrawer'
import { MIN_AMOUNT_TO_VISIT_APP_BEFORE_ASKING_FOR_LOGIN } from '../../../consts/main'
import { CuponDrawer } from '../components/CuponDrawer'
import { Restaurant } from '../../../types/restaurants'
import { request } from '../../../helpers/request'

export const DiscountsScreenWithContext = function ({
  isStar,
  isLoadingDiscounts,
  discounts,
  discountsSortedByDcto,
  discountsSortedByKms,
  defaultView,
  displayMode,
}: {
  isStar: boolean
  isLoadingDiscounts: boolean
  discounts: Discount[]
  discountsSortedByDcto: any
  discountsSortedByKms: any
  defaultView: DiscountsViewsEnum
  displayMode: DiscountsDisplayEnum
}) {
  // Add a static variable to track if we've shown the login prompt
  const [hasShownLoginPrompt, setHasShownLoginPrompt] = useState(() => {
    // Check sessionStorage to persist the state during the session
    return sessionStorage.getItem('hasShownLoginPrompt') === 'true'
  })

  const urlOpenDiscountId = getUrlParam('openDiscountId')
  const [discountInDrawer, setDiscountInDrawer] = useState<Discount | null | undefined>(null)
  const [openMenuDrawer, setOpenMenuDrawer] = useState(false)
  const { setOpenLoginDialog, auth, amountOfVisits } = useContext(AppContext)
  const [restaurantList, setRestaurantList] = useState<Restaurant[]>([])

  useEffect(() => {
    if (isStar) {
      request('admin/restaurants').then((response) => {
        setRestaurantList(response?.data?.restaurants || [])
      })
    }
  }, [])

  useEffect(() => {
    if (
      !hasShownLoginPrompt &&
      amountOfVisits > MIN_AMOUNT_TO_VISIT_APP_BEFORE_ASKING_FOR_LOGIN &&
      auth === false
    ) {
      setOpenLoginDialog(true)
      setHasShownLoginPrompt(true)
      sessionStorage.setItem('hasShownLoginPrompt', 'true')
    }
  }, [auth, hasShownLoginPrompt])

  useEffect(() => {
    if (urlOpenDiscountId) {
      const preOpenedDiscountByUrl = discounts.find(
        (discount: Discount) => discount.id === urlOpenDiscountId
      )
      setDiscountInDrawer(preOpenedDiscountByUrl)
    }
  }, [urlOpenDiscountId, discounts])

  const handleDiscountClick = (discount: Discount | undefined) => {
    setUrlParam('openDiscountId', discount?.id || '')
    if (process.env.NODE_ENV !== 'production') {
      console.log(discount)
    }
    setDiscountInDrawer(discount)
  }

  // state for discountContext
  const [openLoginDrawer, setOpenLoginDrawer] = useState(false)
  const [loginDrawerProps, setLoginDrawerProps] = useState(defaultLoginDrawerProps)

  const [openReportErrorDrawer, setOpenReportErrorDrawer] = useState(false)
  const [reportErrorDrawerProps, setReportErrorDrawerProps] = useState(
    defaultReportErrorDrawerProps
  )

  const [openAdDrawer, setOpenAdDrawer] = useState(false)
  const [adDrawerProps, setAdDrawerProps] = useState(defaultAdDrawerProps)

  const [view, setView] = useState(defaultView)

  const [openCuponModal, setOpenCuponModal] = useState(false)
  const [cuponModalProps, setCuponModalProps] = useState(defaultCuponModalProps)

  const discountContextValue: DiscountContextType = {
    openLoginDrawer,
    handleOpenLoginDrawer: (props: OpenLoginDrawerProps) => {
      setLoginDrawerProps(props)
      setOpenLoginDrawer(true)
    },
    openReportErrorDrawer,
    handleOpenReportErrorDrawer: (props: OpenReportErrorProps) => {
      setReportErrorDrawerProps(props)
      setOpenReportErrorDrawer(true)
    },
    handleDiscountClick,
    setOpenMenuDrawer,
    openAdDrawer,
    handleOpenAdDrawer: (props: OpenAdDrawerProps) => {
      setAdDrawerProps(props)
      setOpenAdDrawer(true)
    },
    openCuponModal: (props: OpenCuponModalProps) => {
      setCuponModalProps(props)
      setOpenCuponModal(true)
    },
    restaurantList,
  }

  let childrenDiscounts: Discount[] | null = null
  if (discountInDrawer?.childrenIds) {
    childrenDiscounts = discountInDrawer.childrenIds
      .map((cId) => discounts.find((d) => d.id === cId))
      .filter((d): d is Discount => d !== undefined)
  } else if (discountInDrawer?.parentId) {
    childrenDiscounts = discounts
      .filter((d) => d.id === discountInDrawer.parentId)
      .filter((d): d is Discount => d !== undefined)
  }

  return (
    <DiscountContext.Provider value={discountContextValue}>
      <DiscountViewsRouter
        isStar={isStar}
        isLoadingDiscounts={isLoadingDiscounts}
        discounts={discounts}
        discountsSortedByDcto={discountsSortedByDcto}
        discountsSortedByKms={discountsSortedByKms}
        view={view}
        setView={setView}
        displayMode={displayMode}
      />
      <MenuDrawer
        setView={setView}
        openMenuDrawer={openMenuDrawer}
        setOpenMenuDrawer={setOpenMenuDrawer}
      />
      <DiscountDrawer
        discount={discountInDrawer || null}
        isStar={isStar}
        childrenDiscounts={childrenDiscounts}
      />
      <LoginDrawer
        open={openLoginDrawer}
        setOpen={setOpenLoginDrawer}
        loginDrawerProps={loginDrawerProps}
      />
      <CuponDrawer
        open={openCuponModal}
        setOpen={setOpenCuponModal}
        cuponModalProps={cuponModalProps}
      />
      <AdDrawer open={openAdDrawer} setOpen={setOpenAdDrawer} adDrawerProps={adDrawerProps} />
      <ReportErrorDrawer
        open={openReportErrorDrawer}
        setOpen={setOpenReportErrorDrawer}
        reportErrorDrawerProps={reportErrorDrawerProps}
      />
    </DiscountContext.Provider>
  )
}
