import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { getUrlParam } from '../../helpers/others'
import { useCookies } from 'react-cookie'
import { request } from '../../helpers/request'
import { primaryPrimary } from '../../style'
import { FormToLogin } from './ValidateCuponScreen'

export const CuponsMetricsScreen = () => {
  const [cookies, setCookie] = useCookies<any>(['savemoney'])
  const [localSecretKey, setLocalSecretKey] = useState(cookies.localSecretKey?.toLowerCase())
  const [isLoading, setIsLoading] = useState(false)
  const [metricsData, setMetricsData] = useState<any>(null)
  const localId = getUrlParam('localId')

  const fetchMetricsData = () => {
    setIsLoading(true)
    if (localSecretKey && localId) {
      request('get_cupons_metrics', {
        method: 'POST',
        body: JSON.stringify({
          localSecretKey,
          localId,
        }),
      }).then((response) => {
        console.log('response', response)
        setMetricsData(response?.data?.generatedCuponsData || null)
        setIsLoading(false)
      })
    }
  }

  useEffect(() => {
    fetchMetricsData()
  }, [localSecretKey, localId])

  useEffect(() => {
    setCookie('localSecretKey', localSecretKey)
  }, [localSecretKey])

  if (!localId) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h6" sx={{ mt: 4 }}>
          Intenta con ontro Id de local
        </Typography>
      </Container>
    )
  }

  if (!localSecretKey) {
    return <FormToLogin setLocalSecretKey={setLocalSecretKey} />
  }

  return (
    <div style={{ backgroundColor: primaryPrimary, height: '100vh', display: 'flex' }}>
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <Button variant="contained" onClick={fetchMetricsData} disabled={isLoading}>
              Actualizar
            </Button>
          </Box>
          {isLoading ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <CircularProgress size={60} />
            </Box>
          ) : (
            <>
              {metricsData && (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, mb: 4 }}>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="h3">{metricsData.length}</Typography>
                      <Typography variant="subtitle1">Generados</Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="h3">
                        {metricsData.filter((metric: any) => metric.validatedAt).length}
                      </Typography>
                      <Typography variant="subtitle1">Validados</Typography>
                    </Box>
                  </Box>

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Generated At</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>User ID</TableCell>
                          <TableCell>Validated At</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {metricsData.map((metric: any) => (
                          <TableRow key={metric.id}>
                            <TableCell>
                              {new Date(metric.generatedAt._seconds * 1000).toLocaleDateString() +
                                ' ' +
                                new Date(metric.generatedAt._seconds * 1000).toLocaleTimeString(
                                  [],
                                  { hour: '2-digit', minute: '2-digit' }
                                )}
                            </TableCell>
                            <TableCell>{metric.validatedAt ? 'Validated' : 'Pending'}</TableCell>
                            <TableCell>
                              {metric.user ? `...${String(metric.user.id).slice(-8)}` : '-'}
                            </TableCell>
                            <TableCell>
                              {metric.validatedAt
                                ? new Date(
                                    metric.validatedAt._seconds * 1000
                                  ).toLocaleDateString() +
                                  ' ' +
                                  new Date(metric.validatedAt._seconds * 1000).toLocaleTimeString(
                                    [],
                                    { hour: '2-digit', minute: '2-digit' }
                                  )
                                : '-'}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </>
          )}
          <Button
            variant="text"
            color="inherit"
            size="small"
            onClick={() => setLocalSecretKey('')}
            sx={{ opacity: 0.7 }}
          >
            Cerrar sesión
          </Button>
        </Paper>
      </Container>
    </div>
  )
}
