import React, { useContext } from 'react'
import { Discount } from '../../../../types/discount'
import { neutralWhite, primary90White, primaryPrimary } from '../../../../style'
import { DiscountContext } from '../../../../contexts/DiscountContext'
import { NAME_OF_DAYS, normalizeDaysToText } from '../../../../helpers/others'
import { MAX_PANEL_WIDTH } from './DiscountsTableView'
import { isChrome, isMobile } from '../../../../helpers/device'
import { AppContext } from '../../../../contexts/AppContext'
import { ClubEnums } from '../../../../types/clubs'
import { MAINTAGV3Enum } from '../../../../types/mainTagV3'

const CLUBS_COLORS: {
  [key: string]: string
} = {
  Santander: '#FCE0CD',
  'Banco de Chile': '#D4E3F7',
  'Banco Security': '#D7CCFB',
  Itau: '#FADBAD',
  BICE: '#C4EBF9',
  Scotiabank: '#F98E86',
  BCI: '#FDFFD3',
  'Banco Estado': '#D88035',
  CMR: '#D4F0CB',
  Claro: '#D33023', // red
  FullCopec: '#4D86D1', // blue
  Tenpo: '#3F433E', // black
  'Banco Ripley': '#70408D', // purple
  MACH: '#560DDE',
  Entel: '#0F2DF5',
  'Vecino Las Condes': 'pink',
  'La Tercera': '#8D2B2E',
  'Club Lectores El Mercurio': '#EEF0F0',
  'Mi Vitacura': '#DBE2A4',
  'La Reina': '#2E657B',
}

const CLUBS_LABEL_COLOR: { [key: string]: string } = {
  Santander: '#B0584E',
  'Banco de Chile': '#374667',
  'Banco Security': '#7E5E9D',
  Itau: '#AB8255',
  BICE: '#374667',
  Scotiabank: '#9E3B30',
  BCI: '#D9A13D',
  'Banco Estado': 'white',
  CMR: '#429F65',
  Claro: 'white',
  FullCopec: 'white',
  Tenpo: '#8FF49E',
  'Banco Ripley': 'white',
  MACH: 'white',
  Entel: 'white',
  'Vecino Las Condes': 'black',
  'La Tercera': 'white',
  'Club Lectores El Mercurio': 'black',
  'La Reina': 'white',
}

export const DiscountPanel = ({
  panel,
  discountsWithParentId,
  isStar,
}: {
  panel: Discount[]
  discountsWithParentId: Discount[]
  isStar: boolean
}) => {
  const { clubs } = useContext(AppContext)

  const {
    handleDiscountClick,
    // restaurantList
  } = useContext(DiscountContext)
  const subTarjetaIdToLabel: { [key: string]: string } = {}
  Object.keys(clubs || []).forEach((clubId) => {
    const clubIdEnum = clubId as ClubEnums
    if (clubs && clubId in clubs) {
      const clubSubTarjetas = clubs[clubIdEnum]?.subTarjetas || []
      clubSubTarjetas.forEach((clubSubTarjeta) => {
        subTarjetaIdToLabel[clubSubTarjeta.id] = clubSubTarjeta.label
      })
    }
  })
  const subTarjetaWritten: string[] = []
  const alreadyClubsWritten: string[] = []
  const allDiscountsHaveSameTope: { [key: string]: boolean } = {}
  const borderSpacing = isChrome && !isMobile ? 2 : 1

  return (
    <table
      style={{
        backgroundColor: primary90White,
        textAlign: 'center',
        // borderSpacing: 2, // default is 2px
        borderCollapse: 'separate',
        borderSpacing,
        width: '100%',
        maxWidth: MAX_PANEL_WIDTH,
      }}
    >
      <thead>
        <tr
          style={{
            backgroundColor: primaryPrimary,
            color: neutralWhite,
          }}
        >
          <th>DÍA</th>
          <th>LOCAL</th>
          <th>DCTO.</th>
          {/* <th>
            <StarRoundedIcon style={{ color: googleMapsStarColor, fontSize: 14 }} />
          </th> */}
          {/* <th>#</th>
          <th>res?</th>
          <th>R</th> */}
          {/* <th>beer?</th>
          <th>book</th> */}
        </tr>
      </thead>
      <tbody>
        {panel.map((discount, index) => {
          const subTarjetaKey = `${discount.club}-${discount.subTarjeta}`

          // revisamos si todos los descuentos de esa subTarjeta tienen el mismo tope
          // revisamos solo si subTarjetaKey no fue agregada ya a allDiscountsHaveSameTope.
          if (!(subTarjetaKey in allDiscountsHaveSameTope) && discount.topeDescuento) {
            allDiscountsHaveSameTope[subTarjetaKey] = panel
              .filter((d) => d.club === discount.club && d.subTarjeta === discount.subTarjeta)
              .every((d) => d.topeDescuento === discount.topeDescuento)
          }

          const subTarjetaLabel = discount.subTarjeta
            .split(';')
            .map((st) => {
              return subTarjetaIdToLabel[st.trim()] || ''
            })
            .join(' o ')
          const shouldWriteSubTarjeta =
            !subTarjetaWritten.includes(subTarjetaKey) && subTarjetaLabel

          if (shouldWriteSubTarjeta) subTarjetaWritten.push(subTarjetaKey)

          const shouldWriteClub = !alreadyClubsWritten.includes(discount.club)
          if (!alreadyClubsWritten.includes(discount.club)) {
            alreadyClubsWritten.push(discount.club)
          }
          const dayLabel =
            NAME_OF_DAYS[discount.diasNormalizados] ||
            normalizeDaysToText(discount.diasNormalizados)

          let localLabel = discount.local
          if (discount.childrenIds) {
            localLabel = localLabel.replace('Restaurantes', '')
            localLabel += ' ('
            const childrenDiscounts = discountsWithParentId.filter(
              (d) => d.parentId === discount.id
            )
            childrenDiscounts.forEach((d) => {
              localLabel += `${d.local?.trim()}, `
            })
            localLabel = localLabel.slice(0, -2)
            localLabel += ')'
            // localLabel += ` (${discount.childrenIds.length} restaurantes)`
          }

          let cantidadDescuentoLabel = discount.cantidadDescuento
            ? `${discount.cantidadDescuento} %`
            : '-'
          if (discount.mainTagV3?.includes(MAINTAGV3Enum.BENCINA) && discount.pesosPorLitro) {
            cantidadDescuentoLabel = ` $${discount.pesosPorLitro}/lt`
          }

          return (
            <>
              {shouldWriteClub && (
                <tr key={index + 'A'}>
                  <td
                    colSpan={3}
                    style={{
                      backgroundColor: CLUBS_COLORS[discount.club],
                      color: CLUBS_LABEL_COLOR[discount.club],
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {/* <img
                        src={clubs ? clubs[discount.club]?.img : ''}
                        style={{
                          width: 12,
                          height: 12,
                          objectFit: 'contain',
                        }}
                      /> */}
                      <img
                        src={clubs ? clubs[discount.club]?.img : ''}
                        style={{
                          width: 12,
                          marginRight: 10,
                          height: 12,
                          objectFit: 'contain',
                        }}
                      />
                      {discount.club.toUpperCase()}
                    </div>
                  </td>
                </tr>
              )}
              {shouldWriteSubTarjeta && (
                <tr key={index + 'B'}>
                  <td
                    colSpan={3}
                    style={{
                      backgroundColor: CLUBS_COLORS[discount.club],
                      color: CLUBS_LABEL_COLOR[discount.club],
                    }}
                  >
                    <div>{subTarjetaLabel}</div>
                    {allDiscountsHaveSameTope[subTarjetaKey] &&
                      discount.topeDescuento !== 'No especificado' && (
                        <div>Tope: {discount.topeDescuento}</div>
                      )}
                  </td>
                </tr>
              )}
              <tr key={index + 'C'} style={{ backgroundColor: neutralWhite }}>
                <td style={{ fontWeight: dayLabel == 'Todos' ? 'bold' : 'normal' }}>{dayLabel}</td>
                <td
                  style={{ color: primaryPrimary, cursor: 'pointer' }}
                  onClick={() => handleDiscountClick(discount)}
                >
                  {/* {isStar && discount.gMapsResults?.filter((r) => r.reservable === true)?.length
                    ? `📅`
                    : ''} */}
                  {localLabel}
                  {isStar && discount.comments?.filter((c) => c.commentType === 'ERROR')?.length
                    ? `🚨${discount.comments.filter((c) => c.commentType === 'ERROR').length}`
                    : ''}
                  {/* {isStar
                    ? restaurantList.find((r) => r.slug === discount.local)
                      ? restaurantList.find((r) => r.slug === discount.local)?.reservationSystem ===
                        'COVER_MANAGER'
                        ? '✅'
                        : '❌'
                      : ''
                    : ''} */}
                </td>
                <td>{cantidadDescuentoLabel}</td>
                {/* <td>{discount.mainTagV3.join(',')}</td> */}
                {/* <td>
                {new Intl.DateTimeFormat('es-ES', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                }).format(new Date((discount?.gMapsOutputTime?._seconds | 0) * 1000))}
              </td> */}
                {/* <td>{discount.subTypeV2.join(',')}</td> */}
                {/* <td>{discount.regiones.join(' ')}</td> */}
                {/* <td>{getAvgRating(discount)}</td> */}
                {/* <td>{discount.gMapsResults?.length}</td> */}
                {/* <td>{isRestoBarByGMaps ? '' : 'X'}</td> */}
                {/* <td>{discount.tipoConsumo}</td> */}

                {/* <td>{discount.gMapsResults?.map((r) => r.servesBeer).some((s) => s) ? 'Y' : ''}</td>
              <td>{discount.gMapsResults?.map((r) => r.reservable).some((s) => s) ? 'Y' : ''}</td> */}
                {/* <td>{!isRestoBarByGMaps && !discount.childrenIds ? 'X' : ''}</td> */}
              </tr>
            </>
          )
        })}
      </tbody>
    </table>
  )
}
