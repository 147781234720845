import React, { useContext, useEffect, useState } from 'react'
import { SMBottomDrawer } from '../../../SMComponents/SMDrawer'
import { SMContent, SMFooter, SMVerticalDiv } from '../../../SMComponents/SMView'
import { SMH2, SMH3, SMP } from '../../../SMComponents/SMText'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { OpenCuponModalProps } from '../../../contexts/DiscountContext'
import { logSMEvent } from '../../../events/logEvent'
import { QRCodeSVG } from 'qrcode.react'
import { Link } from '@mui/material'
import { ActiveGeneratedCuponInUser, AppContext } from '../../../contexts/AppContext'
import { request } from '../../../helpers/request'
import { doesAuthHaveThisCuponActive } from './CuponCTAButton'
import { SMShareButton } from '../../common/SMButton'
import { neutralWhite, primaryPrimary } from '../../../style'

const SMQRCode = ({ value }: { value: string }) => {
  // create a real QR Code with the link in the value
  return (
    <SMVerticalDiv horizontalCenter>
      <QRCodeSVG
        value={value}
        size={140} // Size of the QR code
        bgColor="#ffffff" // Background color
        fgColor="#000000" // Foreground color
        level="H" // Error correction level ('L', 'M', 'Q', 'H')
      />
    </SMVerticalDiv>
  )
}

const formatExpiresAtDate = (date: any) => {
  if (date?._seconds) {
    const dateInMMDDYYY = new Date(date._seconds * 1000).toLocaleString()
    const ddMMYYYYList = dateInMMDDYYY.split(',')[0].split('/')
    const ddMMYYYY = `${ddMMYYYYList[1]}/${ddMMYYYYList[0]}/${ddMMYYYYList[2]}`
    const time = dateInMMDDYYY.split(',')[1]
    return `${ddMMYYYY} a las ${time}`
  }
  return ''
}

export const CuponDrawer = ({
  open,
  setOpen,
  cuponModalProps,
}: {
  open: boolean
  setOpen: (newState: any) => void
  cuponModalProps: OpenCuponModalProps
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [activatedCupon, setActivatedCupon] = useState<ActiveGeneratedCuponInUser | null>(null)
  const { handleOpenSnackBar } = useContext(AppContext)
  const { setAuth } = useContext(AppContext)
  const { discount, userAuth } = cuponModalProps
  const isCuponActivatedByUser = doesAuthHaveThisCuponActive(discount.id, userAuth)

  useEffect(() => {
    if (isCuponActivatedByUser && open) {
      const activeCupon = userAuth?.activeGeneratedCupons?.find(
        (cupon) => cupon.discountId === discount.id
      )
      if (activeCupon) {
        setActivatedCupon(activeCupon)
      }
    }
  }, [userAuth, isCuponActivatedByUser, open])

  const handleGenerateCuponAndSaveIt = async () => {
    if (!userAuth) {
      logSMEvent('ERROR', {
        message: 'No se pudo generar el cupón por falta de auth',
        discountId: discount.id,
      })
      handleOpenSnackBar({
        type: 'error',
        message: 'Error al generar el código QR.',
      })
      return
    }
    setIsLoading(true)
    const response = await request(
      'generate_cupon',
      {
        method: 'POST',
        body: JSON.stringify({
          discountId: discount.id,
          isFrontendProd: true, // <- genera el link del cupon para ser validado en staging o en prod.
        }),
      },
      userAuth
    )
    if (response?.status === 'success' && response?.data?.cupon) {
      const newGeneratedCupon = response?.data?.cupon as ActiveGeneratedCuponInUser
      logSMEvent('CUPON_GENERATED_SUCCESS', {
        userId: userAuth.id,
        ...newGeneratedCupon,
      })
      setActivatedCupon(newGeneratedCupon)
      const newGeneratedCuponsList: ActiveGeneratedCuponInUser[] = [
        ...(userAuth?.activeGeneratedCupons || []),
        newGeneratedCupon,
      ]
      setAuth({
        ...userAuth,
        activeGeneratedCupons: newGeneratedCuponsList,
      })
    } else {
      handleOpenSnackBar({
        type: 'error',
        message: 'Algo salió mal al generar el código QR.',
      })
    }
    setIsLoading(false)
    return
  }

  if (!cuponModalProps.userAuth) return null

  return (
    <SMBottomDrawer
      open={open}
      onClose={() => {
        setOpen(false)
        setActivatedCupon(null)
      }}
      onOpen={() => setOpen(true)}
      sx={{ zIndex: 300 }}
    >
      <SMContent
        sx={{
          overflowY: 'auto', // to allow scrolling
        }}
      >
        {activatedCupon && activatedCupon.cuponQRLink ? (
          <>
            <SMVerticalDiv horizontalCenter>
              <SMH3 center>Presenta este cupón en el establecimiento</SMH3>
              <SMP>
                Asegurate de leer bien las condiciones del cupón antes de dirigirte al local. Cada
                persona debe descargar su QR y mostrarlo en el local.
              </SMP>
            </SMVerticalDiv>
            <SMVerticalDiv horizontalCenter>
              <SMH3>{cuponModalProps.discount.titulo}</SMH3>
              <SMQRCode value={activatedCupon.cuponQRLink} />
              {activatedCupon.generatedAt && (
                <SMP small>Generado el {formatExpiresAtDate(activatedCupon.generatedAt)}.</SMP>
              )}
              {activatedCupon.expiresAt && (
                <SMP>Expira el {formatExpiresAtDate(activatedCupon.expiresAt)}.</SMP>
              )}
              <SMShareButton
                title="Conoce SaveMoney"
                text={`
¡Hola! Te invito a que descargues la app de SaveMoney para que te enteres de todos los descuentos que tienes con tus tarjetas.

👉 AppStore: https://apps.apple.com/us/app/savemoneycl/id6465695551

👉 Google Play: https://play.google.com/store/apps/details?id=cl.savemoney.www.twa
`}
                onClick={() => {}}
                buttonName="Compartir_cupon"
                sx={{
                  minWidth: 'fit-content', // to override the default minWidth
                  minHeight: 0, // to override the default minHeight
                  maxHeight: 32,
                  height: 32,
                  padding: 10,
                  textAlign: 'start',
                  color: primaryPrimary,
                  backgroundColor: neutralWhite,
                  border: `1px solid ${primaryPrimary}`,
                  gap: 6,
                  whiteSpace: 'nowrap', // Prevent text wrapping
                  maxWidth: 140,
                }}
              >
                <SMP sx={{ color: primaryPrimary }}>Comparte la app</SMP>
              </SMShareButton>
            </SMVerticalDiv>
          </>
        ) : (
          <>
            <SMVerticalDiv>
              <SMH2>Confirma que quieres generar este cupón</SMH2>
              {/* {cuponModalProps.discount.titulo} */}
            </SMVerticalDiv>
            <SMVerticalDiv>
              {/* professional QR placeholder */}
              <SMH3>{cuponModalProps.discount.titulo}</SMH3>
              <SMP>{cuponModalProps.discount.descripcion}</SMP>
            </SMVerticalDiv>
            <SMVerticalDiv horizontalCenter>
              <SMPrimaryButton
                fullWidth
                maxWidth
                loading={isLoading}
                onClick={() => {
                  handleGenerateCuponAndSaveIt()
                }}
              >
                Sí, generar cupón
              </SMPrimaryButton>
            </SMVerticalDiv>
          </>
        )}
      </SMContent>
      <SMFooter>
        {activatedCupon ? (
          <SMVerticalDiv fullWidth horizontalCenter>
            <SMPrimaryButton
              fullWidth
              maxWidth
              loading={isLoading}
              onClick={() => {
                setOpen(false)
              }}
            >
              Cerrar
            </SMPrimaryButton>
            <SMP>
              Si tienes alguna duda, contactanos a nuestro{' '}
              <Link
                href="https://wa.me/56974769848"
                target="_blank"
                rel="noopener"
                sx={{ textDecoration: 'none' }}
              >
                WhatsApp.
              </Link>
            </SMP>
          </SMVerticalDiv>
        ) : (
          <></>
        )}
      </SMFooter>
    </SMBottomDrawer>
  )
}
