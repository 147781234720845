import React, { useContext, useEffect, useState } from 'react'
import { Discount } from '../../../types/discount'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { addUtmParamsToUrl } from '../../../helpers/others'
import { SMP } from '../../../SMComponents/SMText'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import { neutralWhite, primaryPrimary } from '../../../style'
import { logSMEvent } from '../../../events/logEvent'
import { MAINTAGV3Enum } from '../../../types/mainTagV3'
import { AppContext } from '../../../contexts/AppContext'
import useObserver from '../../../hooks/useObserver'
import { CuponCTAButton } from './CuponCTAButton'
import { todayChar } from '../../../helpers/getDaysFilters'

export enum CTAButtonSource {
  CARD_FOOTER = 'card_footer',
  DISCOUNT_DRAWER = 'discount_drawer',
  MAP_CARD = 'map_card',
}

export const CTAButton = ({
  discount,
  source,
  category,
}: {
  discount: Discount
  source: CTAButtonSource
  category?: MAINTAGV3Enum | 'SaveMoney'
}) => {
  const { auth } = useContext(AppContext)
  const [wasObserved, setWasObserved] = useState(false) // is or was observed

  const handleObservation = () => {
    setWasObserved(true)
  }

  const componentRef = useObserver(handleObservation)

  const CTAlogInfo = {
    discountId: discount.id,
    discountClub: discount.club,
    discountTitle: discount.titulo,
    isCoverManager: String(discount.isCoverManager),
    hasCoverManagerVariablePlan: String(discount.hasCoverManagerVariablePlan),
    discountDays: discount.diasNormalizados,
    dayOfTheWeek: todayChar,
    source,
    isPromotedDiscount: discount.promoted,
    userId: auth ? auth.id : null,
    ctaText: discount.CTA?.text,
    ctaUrl: discount.CTA?.url,
    isLoggedIn: auth ? true : false,
    authPhoneNumber: auth ? auth.phoneNumber : null,
    authName: auth ? auth.name : null,
    authEmail: auth ? auth.email : null,
  }

  useEffect(() => {
    if (wasObserved) {
      logSMEvent(`CTA_BUTTON_OBSERVED_${source}`, CTAlogInfo)
    }
  }, [wasObserved])

  if (discount.cupon) {
    return (
      <CuponCTAButton discount={discount} source={source} category={category} ref={componentRef} />
    )
  }

  if (!discount.CTA || !discount.CTA.text || !discount.CTA.url) {
    return null
  }
  return (
    <SMPrimaryButton
      ref={componentRef}
      fullWidth={true}
      small={true}
      sx={{
        minWidth: 'fit-content', // to override the default minWidth
        minHeight: 0, // to override the default minHeight
        maxHeight: 32,
        height: 32,
        padding: 10,
        textAlign: 'start',
        color: neutralWhite,
        backgroundColor: primaryPrimary,
        gap: 6,
        whiteSpace: 'nowrap', // Prevent text wrapping
        maxWidth: 240,
      }}
      onClick={(e: any) => {
        e.stopPropagation()
        logSMEvent(`CTA_BUTTON_CLICKED_${source}`, CTAlogInfo)
      }}
      href={addUtmParamsToUrl({ url: discount.CTA.url, id: discount.id })}
    >
      <SMP white>{discount.CTA.text}</SMP>
      <OpenInNewRoundedIcon htmlColor={neutralWhite} sx={{ fontSize: 14 }} />
    </SMPrimaryButton>
  )
}
