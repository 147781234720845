import React, { useContext, useState } from 'react'

import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import SportsBarRoundedIcon from '@mui/icons-material/SportsBarRounded'
import LunchDiningRoundedIcon from '@mui/icons-material/LunchDiningRounded'
import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded'
import BrunchDiningRoundedIcon from '@mui/icons-material/BrunchDiningRounded'
import CoffeeRoundedIcon from '@mui/icons-material/CoffeeRounded'
import DinnerDiningRoundedIcon from '@mui/icons-material/DinnerDiningRounded'
import RamenDiningRoundedIcon from '@mui/icons-material/RamenDiningRounded'
import LocalPizzaRoundedIcon from '@mui/icons-material/LocalPizzaRounded'
import IcecreamRoundedIcon from '@mui/icons-material/IcecreamRounded'
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded'
import FastfoodRoundedIcon from '@mui/icons-material/FastfoodRounded'
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen'
import SetMealRoundedIcon from '@mui/icons-material/SetMealRounded'
import BakeryDiningRoundedIcon from '@mui/icons-material/BakeryDiningRounded'
import whatsappIcon from '../../../assets/whatsappIcon.svg'
import AnnouncementRoundedIcon from '@mui/icons-material/AnnouncementRounded'
import Divider from '@mui/material/Divider'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import InputAdornment from '@mui/material/InputAdornment'
import SendRoundedIcon from '@mui/icons-material/SendRounded'

import { AppContext, UserAuthType } from '../../../contexts/AppContext'
import { logSMEvent } from '../../../events/logEvent'
import {
  neutral50White,
  neutralBlack,
  neutralWhite,
  primary90White,
  primaryPrimary,
  secondary40White,
} from '../../../style'
import { addUtmParamsToUrl, normalizeDaysToText } from '../../../helpers/others'
import { ShareDiscountButton } from '../../common/SMButton'
import { Rating } from './map/DiscountMapCard'
import { DiscountContext } from '../../../contexts/DiscountContext'
import { Drawer, IconButton } from '@mui/material'
import { Discount } from '../../../types/discount'
import { capitalizeFirstChar } from '../../../helpers/normalizeText'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { SMH3, SMP } from '../../../SMComponents/SMText'
import discountCardStyle from './discountCard.module.css'
import { SMChip } from '../../../SMComponents/SMChip'
import { SMHorizontalDiv, SMVerticalDiv } from '../../../SMComponents/SMView'
import { formatPrice } from '../../../helpers/formatPrice'
import { StarOptions } from '../../admin/components/StarOptions'
import { DiscountChildrenFrame } from './DiscountChildrenFrame'
import { SMTextField } from '../../../SMComponents/SMInput'
import {
  COMMENT_DISCOUNTS_FIELDS_REQUIRED,
  isAuthAllowedToCommentDiscounts,
} from '../../../consts/loginRestrictions'
import { request } from '../../../helpers/request'
import { CTAButton, CTAButtonSource } from './CTAButton'

const googleFoodAndDrinkTypesToMUIIcons: {
  [key: string]: React.ReactElement | null
} = {
  american_restaurant: null,
  bar: <SportsBarRoundedIcon />,
  brunch_restaurant: <BrunchDiningRoundedIcon />,
  restaurant: <RestaurantRoundedIcon />,
  food: null,
  point_of_interest: null,
  establishment: null,
  event_venue: null,
  coffee_shop: <CoffeeRoundedIcon />,
  cafe: null,
  store: null,
  italian_restaurant: <DinnerDiningRoundedIcon />,
  japanese_restaurant: <RamenDiningRoundedIcon />,
  pizza_restaurant: <LocalPizzaRoundedIcon />,
  ice_cream_shop: <IcecreamRoundedIcon />,
  fast_food_restaurant: <FastfoodRoundedIcon />,
  indian_restaurant: <SoupKitchenIcon />,
  hamburger_restaurant: <LunchDiningRoundedIcon />,
  sushi_restaurant: <SetMealRoundedIcon />,
  breakfast_restaurant: <BakeryDiningRoundedIcon />,
  vegetarian_restaurant: null,
  vietnamese_restaurant: null,
  chinese_restaurant: null,
  mexican_restaurant: null,
  vegan_restaurant: null,
  bed_and_breakfast: null,
  child_care_agency: null,
  korean_restaurant: null,
  thai_restaurant: null,
}

const { discountHeaderFrame, backgroundImage, gMapPhotoInSlider, closeCardButton } =
  discountCardStyle

export const DiscountStoreInfo = ({ gMapsResults }: any) => {
  const allTypes = gMapsResults.map((result: any) => result.types).flat() as string[]
  const allTypesSet = [...new Set(allTypes)]
  return (
    <div>
      <SMP bold black className="subtitle-2" style={{ margin: '18px 0px' }}>
        Sobre el local:
      </SMP>
      <div style={{ display: 'flex' }}>
        <p style={{ marginRight: 10 }}>Categorias:</p>
        {allTypesSet.map((type) => {
          if (googleFoodAndDrinkTypesToMUIIcons[type] !== undefined) {
            return (
              <div
                key={type}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                }}
              >
                {googleFoodAndDrinkTypesToMUIIcons[type]}
              </div>
            )
          } else {
            return (
              <p
                key={type}
                style={{
                  color: neutral50White,
                }}
              >
                {type},{' '}
              </p>
            )
          }
        })}
      </div>
      {gMapsResults[0]?.regularOpeningHours?.weekdayDescriptions && (
        <>
          <p style={{ marginTop: 10 }}>Horarios: </p>
          {gMapsResults[0].regularOpeningHours.weekdayDescriptions.map((day: string) => {
            return (
              <p
                key={gMapsResults[0].queryID + day}
                style={{
                  color: neutral50White,
                }}
              >
                {day}
              </p>
            )
          })}
        </>
      )}
    </div>
  )
}

export const DISCOUNT_DRAWER_BODY_WIDTH = 'min(90vw, 750px)'

const GMapsQueryFrame = ({ discount, query }: { discount: Discount; query: string }) => {
  const [showGMapsResults, setShowGMapsResults] = useState(true)
  const queriesGMapsResultsToShow = discount.gMapsResults
    .filter((result: any) => result.query === query) // buscamos solo los resultados de dicha query
    .map((result: any) => {
      return (
        <div
          key={result.queryID + result.formattedAddress}
          style={{ display: 'flex' }}
          onClick={() => {
            logSMEvent('DISCOUNT_DRAWER_GMAPS_LINK_CLICKED', { id: discount.id })
          }}
        >
          <SMP>-</SMP>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px' }}>
            <a
              style={{ color: neutral50White, marginLeft: '8px' }}
              href={result.googleMapsUri}
              target="_blank"
              rel="noreferrer"
            >
              <SMP>{result.formattedAddress || result.formatted_address}</SMP>
            </a>
          </div>
        </div>
      )
    })
  if (queriesGMapsResultsToShow.length === 0) return null
  return (
    <SMHorizontalDiv style={{ alignItems: 'start' }}>
      <LocationOnRoundedIcon fontSize="small" htmlColor={neutral50White} />
      <SMVerticalDiv style={{ gap: 0 }}>
        <SMP>{capitalizeFirstChar(query)}</SMP>
        <div>
          <SMP underline onClick={() => setShowGMapsResults(!showGMapsResults)}>
            {showGMapsResults ? '' : 'Ver resultados de Google Maps'}
          </SMP>
          {showGMapsResults && queriesGMapsResultsToShow}
        </div>
      </SMVerticalDiv>
    </SMHorizontalDiv>
  )
}

export const DiscountDrawer = function ({
  discount,
  isStar,
  childrenDiscounts,
}: {
  discount: Discount | null
  isStar: boolean
  childrenDiscounts: Discount[] | null
}) {
  const { auth, clubs, handleOpenSnackBar } = useContext(AppContext)
  const [newComment, setNewComment] = useState('')
  const [authNewComments, setAuthNewComments] = useState<
    {
      discountId: string
      message: string
    }[]
  >([])
  const { handleOpenReportErrorDrawer, handleDiscountClick, handleOpenLoginDrawer } =
    useContext(DiscountContext)

  const logDiscountAction = (eventName: string) => {
    // ojo que esta condicion tiene que ser la misma que href en la prop del boton
    if (discount) {
      logSMEvent(eventName, {
        id: discount.id,
        url: discount.url,
        userRut: (auth && auth?.rut) || 'none',
        mainTagV3: discount.mainTagV3,
        club: discount.club,
      })
    }
  }

  const handleSendMessage = () => {
    if (!newComment || !discount) return
    const openLoginDrawer = !auth || !isAuthAllowedToCommentDiscounts(auth)
    logSMEvent('COMMENT_CLICKED', {
      id: discount?.id,
      message: newComment,
      openLoginDrawer,
    })
    const handleAction = (userAuth: UserAuthType) => {
      logSMEvent('SEND_COMMENT', {
        discountId: discount?.id,
        userId: (userAuth && userAuth?.id) || 'none',
        message: newComment,
      })
      setNewComment('')
      request(
        'comment_discount',
        {
          method: 'POST',
          body: JSON.stringify({
            discountId: discount.id,
            message: newComment,
          }),
        },
        userAuth
      )
      setAuthNewComments([
        {
          discountId: discount.id,
          message: newComment,
        },
        ...authNewComments,
      ])
      handleOpenSnackBar({
        message: 'Tu comentario ha sido enviado y se publicará mañana.',
        duration: 8000,
      })
    }

    if (openLoginDrawer) {
      handleOpenLoginDrawer({
        source: 'comment_button', // antes era 'like_button'
        title: 'Completa tu perfil para comentar',
        subtitle:
          'Además podrás guardar descuentos, reservar mesas, acceder a beneficios exclusivos y más.',
        fields: COMMENT_DISCOUNTS_FIELDS_REQUIRED,
        buttonLabel: 'Enviar comentario',
        onSubmit: handleAction,
      })
    } else {
      handleAction(auth)
    }
  }

  if (!discount) return <></>

  const subTarjetaLabel = discount.subTarjeta
    ?.split('; ')
    ?.map((stId) => {
      return clubs && clubs[discount.club]?.subTarjetas?.find((st) => st.id == stId)?.label
    })
    ?.join(', ')

  const errorDiscountsComments = discount.comments?.filter((c) => c.commentType === 'ERROR') || []
  const normalDiscountsComments = discount.comments?.filter((c) => c.commentType === 'NORMAL') || []

  return (
    <Drawer
      anchor="right"
      open={discount !== null}
      onClose={() => {
        handleDiscountClick(undefined)
      }}
      sx={{ zIndex: 200 }}
    >
      <div
        style={{
          maxWidth: DISCOUNT_DRAWER_BODY_WIDTH,
          backgroundColor: 'white',
          overflowY: 'auto',
          // prevent scroll of elements that are behind
        }}
      >
        <div>
          <div
            className={discountHeaderFrame}
            style={{
              height: '140px',
              borderRadius: 0,
            }}
          >
            <div className={closeCardButton}>
              <CloseRoundedIcon
                sx={{
                  color: 'white',
                  height: '18px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  return handleDiscountClick(undefined)
                }}
              />
            </div>
            <img // background para que se vea lindo atras de la imagen
              className={backgroundImage}
              src={discount.bgimage}
              alt="."
              referrerPolicy="no-referrer"
            />
            {discount.logo && discount.logo !== discount.bgimage && (
              <img
                style={{
                  objectFit: 'cover',
                }}
                alt="."
                src={discount.logo}
              />
            )}
            <img
              style={{
                marginRight: discount?.gMapsResults ? '2px' : '0px',
                width: '100%',
                objectFit:
                  discount.club == 'Claro' || discount.club == 'Lider Bci' ? 'contain' : 'cover',
              }}
              src={discount?.bgimage}
              alt="."
              referrerPolicy="no-referrer"
            />
            {discount?.gMapsResults
              ?.map((r) => r.photosUrls)
              ?.flat()
              ?.slice(0, 10)
              ?.map((url) => {
                return <img key={url} src={url} alt="." className={gMapPhotoInSlider} />
              })}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '14px',
            gap: 14,
          }}
        >
          <SMHorizontalDiv>
            {discount.club && clubs && discount.club !== 'SaveMoney' ? (
              <img
                src={clubs[discount.club]?.img}
                style={{ maxWidth: 30, maxHeight: 20, objectFit: 'contain' }}
                alt={discount.club}
              />
            ) : null}
            <SMH3>{discount.titulo}</SMH3>
          </SMHorizontalDiv>
          <DiscountChildrenFrame childrenDiscounts={childrenDiscounts} />
          {discount.CTA || discount.cupon ? (
            <CTAButton discount={discount} source={CTAButtonSource.DISCOUNT_DRAWER} />
          ) : null}

          <ShareDiscountButton
            discount={discount}
            buttonname="discount_drawer_share_button"
            sx={{
              border: '1px solid',
              borderColor: primaryPrimary,
              color: primaryPrimary,
              backgroundColor: neutralWhite,

              minWidth: 0, // to override the default minWidth
              minHeight: 0, // to override the default minHeight
              maxWidth: 240,
              maxHeight: 30,
              gap: 4,
            }}
          >
            <SMP sx={{ color: primaryPrimary }}>Compartir</SMP>
            <img
              src={whatsappIcon}
              alt="whatsappIcon"
              style={{
                maxHeight: '18px',
              }}
            />
          </ShareDiscountButton>

          {errorDiscountsComments.length > 0 ? (
            <>
              <SMHorizontalDiv verticalCenter>
                <AnnouncementRoundedIcon style={{ width: 18, marginBottom: -6 }} />
                <SMP bold black className="subtitle-2" style={{ margin: '18px 0px 10px' }}>
                  Errores reportados
                </SMP>
              </SMHorizontalDiv>
              <SMVerticalDiv>
                {errorDiscountsComments
                  .sort((a, b) => b.createdAt._seconds - a.createdAt._seconds)
                  .map((comment, index) => {
                    return (
                      <SMVerticalDiv key={index} sx={{ gap: 4 }}>
                        <SMP black className="subtitle-3">
                          {comment.user?.username || 'Anónimo'}
                        </SMP>
                        <SMP small>
                          {new Intl.DateTimeFormat('es-ES', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                          }).format(new Date(comment.createdAt._seconds * 1000))}
                        </SMP>
                        <SMP black>{comment.message}</SMP>
                        <Divider sx={{ mt: 1 }} />
                      </SMVerticalDiv>
                    )
                  })}
              </SMVerticalDiv>
              <SMChip
                onClick={() =>
                  handleOpenReportErrorDrawer({
                    source: 'discount_drawer',
                    discount,
                  })
                }
              >
                <AnnouncementRoundedIcon sx={{ color: secondary40White, width: 18 }} />
                <p style={{ color: neutralBlack }}>Reportar información incorrecta</p>
              </SMChip>
            </>
          ) : (
            <SMHorizontalDiv>
              <SMChip
                onClick={() =>
                  handleOpenReportErrorDrawer({
                    source: 'discount_drawer',
                    discount,
                  })
                }
              >
                <AnnouncementRoundedIcon sx={{ color: secondary40White, width: 18 }} />
                <p style={{ color: neutralBlack }}>Reportar información incorrecta</p>
              </SMChip>
            </SMHorizontalDiv>
          )}
          <SMP bold black className="subtitle-2" style={{ margin: '18px 0px 8px 0px' }}>
            Información relevante
          </SMP>
          <SMVerticalDiv>
            <div>
              {discount?.gMapsResults?.length > 0 && (
                <Rating mapsResult={discount.gMapsResults[0]} />
              )}
            </div>
            {subTarjetaLabel && (
              <div style={{ display: 'flex', gap: 10 }}>
                <CreditCardRoundedIcon fontSize="small" htmlColor={neutral50White} />
                <SMP>Válido con: {subTarjetaLabel}</SMP>
              </div>
            )}

            <SMHorizontalDiv>
              <CalendarTodayRoundedIcon fontSize="small" htmlColor={neutral50White} />
              <SMP>Válido los días: {normalizeDaysToText(discount.diasNormalizados)}</SMP>
            </SMHorizontalDiv>
            {discount.topeDescuento && (
              <SMHorizontalDiv>
                <PanToolRoundedIcon fontSize="small" htmlColor={neutral50White} />
                <SMP>Tope del descuento: {formatPrice(discount.topeDescuento)}</SMP>
              </SMHorizontalDiv>
            )}
            {discount.fecha_hasta && (
              <p
                style={{
                  backgroundColor: primary90White,
                  padding: '4px 8px',
                  borderRadius: '10px',
                  color: primaryPrimary,
                  width: 'fit-content',
                  // fontWeight: 'bold',
                }}
              >
                Hasta el {discount.fecha_hasta}
              </p>
            )}
            {isStar && <StarOptions discount={discount} />}
            {!discount.cupon && (
              <>
                <SMP bold black className="subtitle-2" style={{ margin: '18px 0px' }}>
                  Revisa la información oficial de {discount.club} aquí:
                </SMP>
                <SMPrimaryButton
                  sx={{
                    border: '1px solid',
                    borderColor: primaryPrimary,
                    color: primaryPrimary,
                    backgroundColor: neutralWhite,
                    minWidth: 0, // to override the default minWidth
                    maxWidth: 240,
                    minHeight: 0, // to override the default minHeight
                    maxHeight: 20,
                    height: 32,
                    gap: 4,
                  }}
                  onClick={() => {
                    logDiscountAction('DISCOUNT_LINK_CLICKED')
                  }}
                  href={addUtmParamsToUrl(discount)}
                  fullWidth={true}
                  small={true}
                >
                  <SMP sx={{ color: primaryPrimary }}>Ir al sitio</SMP>
                  <OpenInNewRoundedIcon fontSize="small" htmlColor={primaryPrimary} />
                </SMPrimaryButton>
              </>
            )}
            {discount.gMapsResults?.length > 0 && (
              <>
                <div>
                  <SMP bold black className="subtitle-2" style={{ margin: '20px 0px 8px 0px' }}>
                    Direcciones
                  </SMP>
                </div>
                {discount.gMapsQueries.map((query: string) => {
                  return <GMapsQueryFrame discount={discount} key={query} query={query} />
                })}
              </>
            )}
          </SMVerticalDiv>

          {isStar && <SMP>{discount.aiQueryInput}</SMP>}
          <SMP bold black className="subtitle-2" style={{ margin: '18px 0px' }}>
            Descripción
          </SMP>
          <SMVerticalDiv sx={{ gap: 6 }}>
            {capitalizeFirstChar(discount.descripcion)
              // replace any number of one single digit followed by a . for the same digit follow by a -    ... la verdad no me acuerdo pq teniamos esto, falta agregar una razón.
              // .replace(/(\d)\./g, '$1---')

              // .split(/(?<!\b(?:dcto|av)|\b\d)\. /i) // ojo! no funciona en iOS antiguos, no cometer mismo error en el futuro.

              .split(/(?:dcto|av)?\. /i) // split on dot not preceded by "dcto" or "av" and has a space after it

              // .split(/(?<![dD]cto)(?<![aA]v)\. /) // no funciona en iOS antiguos. Split on dot not preceded by "dcto" and has a space after it no funciona
              .filter((sentence) => sentence.trim().length > 0) // Filter out empty sentences
              .map((sentence, index) => {
                return (
                  <SMP black key={index}>
                    {'• ' + capitalizeFirstChar(sentence) + '.'}
                  </SMP>
                )
              })}
          </SMVerticalDiv>
          {discount.condiciones && (
            <>
              <SMP bold black className="subtitle-2" style={{ margin: '18px 0px' }}>
                Términos y condiciones
              </SMP>
              <SMP>{discount.condiciones}</SMP>
            </>
          )}

          <SMP bold black className="subtitle-2" style={{ margin: '18px 0px' }}>
            Comentarios
          </SMP>

          <SMVerticalDiv>
            {authNewComments
              .filter((c) => c.discountId == discount.id)
              .map((c, index) => {
                return (
                  <SMVerticalDiv key={index} sx={{ gap: 4 }}>
                    <SMP small>Tu comentario se publicará mañana a las 12:00 hrs</SMP>
                    <SMP>{c.message}</SMP>
                    <Divider sx={{ mt: 1 }} />
                  </SMVerticalDiv>
                )
              })}

            {normalDiscountsComments
              .sort((a, b) => b.createdAt._seconds - a.createdAt._seconds)
              .map((comment, index) => {
                return (
                  <SMVerticalDiv key={index} sx={{ gap: 4 }}>
                    <SMP black className="subtitle-3">
                      {comment.user?.username || 'Anónimo'}
                    </SMP>
                    <SMP small>
                      {new Intl.DateTimeFormat('es-ES', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      }).format(new Date(comment.createdAt._seconds * 1000))}
                    </SMP>
                    <SMP black>{comment.message}</SMP>
                    <Divider sx={{ mt: 1 }} />
                  </SMVerticalDiv>
                )
              })}
            <SMTextField
              value={newComment || ''}
              placeholder="Añade un comentario..."
              sx={{ backgroundColor: 'white' }}
              onChange={(e: any) => setNewComment(e.target.value)}
              onClick={(e: any) => e.stopPropagation()}
              multiline
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        backgroundColor: newComment ? primaryPrimary : neutral50White,
                        padding: '2px 5px',
                        margin: '0px',
                        borderRadius: '50%',
                        display: 'flex',
                      }}
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        sx={{ width: '14px', height: '14px' }}
                        onClick={handleSendMessage}
                      >
                        <SendRoundedIcon
                          sx={{ color: neutralWhite, width: '14px', height: '14px' }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
          </SMVerticalDiv>

          {isStar && discount.gMapsResults && (
            <DiscountStoreInfo gMapsResults={discount.gMapsResults} />
          )}
        </div>
      </div>
    </Drawer>
  )
}
